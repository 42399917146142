import React from "react"


const HourlyButton = props => {
	const buttonText = props.text
	return (
		<div>
			<button className={`btn btn--${props.size} type--uppercase`} href="#" target="" rel="noopener noreferrer">
				<span className="btn__text">{buttonText}</span>
			</button>
		</div>
	)
};
export default HourlyButton