import React, { Component } from "react";
import IRLayout from "../components/layout";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";

//const netlifyIdentity = require('netlify-identity-widget');
import netlifyIdentity from "netlify-identity-widget";

//window.netlifyIdentity = netlifyIdentity;

export class HourlyLogin extends Component {
  componentDidMount() {
    netlifyIdentity.init({
      //container: '#my_login' // defaults to document.body,
    });
    netlifyIdentity.open("login"); // open the modal to the login tab
  }
  render() {
    return (
      <IRLayout>
        <div className="section white">
          <Container>
            <Col xs={12}>
              <h1 className="centered text-padding">Login</h1>
            </Col>
          </Container>
          <Container id="my_login" />
        </div>
      </IRLayout>
    );
  }
}
export default HourlyLogin;
