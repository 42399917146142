import React, { Component } from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IRLayout from "../components/layout";
import ProfileInfo from "../components/single-profile/profile-info";
import SkillTags from "../components/single-profile/profile-skill-tags";
import AreasOfStrength from "../components/single-profile/profile-areas-of-strength";
class Consultant extends Component {
  constructor(props) {
    super(props);
    this.consultant = props.data.datoCmsConsultant;
  }
  render() {
    return (
      <IRLayout>
        <div className="section white">
          <Container>
            <Row>
              <Col xs={12} md={3}>
                <ProfileInfo consultant={this.consultant} />
              </Col>

              <Col xs={12} md={9}>
                <h2 style={{ marginBottom: "24px" }}>Who am I?</h2>
                <div
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: this.consultant.bio
                  }}
                />
                <SkillTags consultant={this.consultant} />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <h2 style={{ marginBottom: "24px" }}>
                  {this.consultant.firstName} can help with:
                </h2>
                <AreasOfStrength consultant={this.consultant} />
              </Col>
            </Row>
          </Container>
        </div>
      </IRLayout>
    );
  }
}
export default Consultant;

export const query = graphql`
  query ConsultantQuery($slug: String!) {
    datoCmsConsultant(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      consultantName
      firstName
      consultantImage {
        url
        fixed(width: 200) {
          ...GatsbyDatoCmsFixed
        }
      }
      bioSnippet
      bio
      expertise
      hourlyRate
      areasOfStrength {
        ... on DatoCmsStrength {
          id
          title
          strengthText
        }
      }
    }
  }
`;
