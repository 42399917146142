import React from "react";
import { graphql } from "gatsby";
import Masonry from "react-masonry-component";
import IRLayout from "../components/layout";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ConsultantCard from "../components/consultant-card";
const HourlyBoostrap = ({ data }) => (
  <IRLayout>
    <div className="section white">
      <Container>
        <Col xs={12}>
          <h1 className="centered text-padding">Our Consultants</h1>
        </Col>
      </Container>
      <Container>
        <Col xs={12}>
          <Masonry className="showcase">
            {data.allDatoCmsConsultant.edges.map(({ node: consultant }) => (
              <ConsultantCard key={consultant.id} consultant={consultant} />
            ))}
          </Masonry>
        </Col>
      </Container>
    </div>
  </IRLayout>
);

export default HourlyBoostrap;

export const query = graphql`
  query HourlyConsultants {
    allDatoCmsConsultant(sort: { fields: [position], order: [ASC] }) {
      edges {
        node {
          id
          consultantName
          bio
          slug
          bioSnippet
          hourlyRate
          consultantImage {
            fluid(maxWidth: 350, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
