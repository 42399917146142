import React from "react";
import Img from "gatsby-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const ProfileInfoSmallBooking = props => {
  const consultant = props.consultant;
  return (
    <div>
      <div className="profile_container">
        <Img
          className="profile-image"
          fixed={consultant.consultantImage.fixed}
        />
        <h5 className="profile_name">{consultant.consultantName}</h5>
        Hourly Rate: £{consultant.hourlyRate}
      </div>
    </div>
  );
};

export default ProfileInfoSmallBooking;
