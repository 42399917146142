import React, { Component } from "react";
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import IRLayout from "../components/layout";
import Masonry from "react-masonry-component";
import ConsultantCard from "../components/consultant-card";
class CategoryTemplate extends Component {
  constructor(props) {
    super(props);
    this.consultant = props.data.myConsultant;
  }
  render() {
    return (
      <IRLayout>
        <div
          className="section white"
          style={{ borderBottom: "1px solid #eee" }}
        >
          <Container>
            <Col xs={12}>
              <Masonry className="showcase">
                {this.consultant.edges.map(({ node: consultant }) => (
                  <ConsultantCard key={consultant.id} consultant={consultant} />
                ))}
              </Masonry>
            </Col>
          </Container>
        </div>
      </IRLayout>
    );
  }
}
export default CategoryTemplate;

export const query = graphql`
  query yuyu($slug: String!) {
    myConsultant: allDatoCmsConsultant(
      filter: { category: { elemMatch: { slug: { in: [$slug] } } } }
    ) {
      edges {
        node {
          id
          consultantName
          bio
          slug
          bioSnippet
          hourlyRate
          areasOfStrength {
            ... on DatoCmsStrength {
              title
              strengthText
            }
          }
          consultantImage {
            fluid(maxWidth: 850, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
