// import the validator library to do most of the heavy lifting
import validator from 'validator'

// add custom function definitions here to use in the exported rule sets
// if you had many of these, you might put them in their own file


// export an object containing the validation rules
export default {

  // define validation rules specific to each of your forms.
  login_rules: [
     { 
      field: 'name', 
      method: validator.isEmpty, 
      validWhen: false, 
      message: 'Name is required.'
    },
    { 
      field: 'email', 
      method: validator.isEmpty, 
      validWhen: false, 
      message: 'Email is required.' 
    },
    { 
      field: 'email',
      method: validator.isEmail, 
      validWhen: true, 
      message: 'That is not a valid email.'
    },
	{ 
      field: 'project_details', 
      method: validator.isEmpty, 
      validWhen: false, 
      message: 'Project details are required.'
    },
  ]
}