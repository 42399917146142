// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-consultant-js": () => import("/opt/build/repo/src/templates/consultant.js" /* webpackChunkName: "component---src-templates-consultant-js" */),
  "component---src-templates-book-consultant-js": () => import("/opt/build/repo/src/templates/book-consultant.js" /* webpackChunkName: "component---src-templates-book-consultant-js" */),
  "component---src-templates-category-list-js": () => import("/opt/build/repo/src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meet-our-consultants-js": () => import("/opt/build/repo/src/pages/meet-our-consultants.js" /* webpackChunkName: "component---src-pages-meet-our-consultants-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

