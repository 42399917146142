import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Button from "react-bootstrap/Button";

/*
const ProfileInfo = props => {
	const consultant = props.consultant
	return (
		<div className="profile_container">
			<Img className="profile-image" fixed={consultant.consultantImage.fixed} />
			<h5 className="profile_name">{consultant.consultantName}</h5>
			Hourly Rate: £{consultant.hourlyRate}
			<Button>
				<Button variant="primary">Primary</Button>
			</Button>
		</div>
	)
};
*/
class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.consultant = props.consultant;
    this.view = props.view;
    this.visibility = props.visibility;
  }

  handleClick() {
    this.view();
    //this.visibility();

    setTimeout(() => this.visibility(), 500);
  }

  //const consultant = props.consultant
  render() {
    return (
      <div>
        <div className="profile_container">
          <Img
            className="profile-image"
            fixed={this.consultant.consultantImage.fixed}
          />
          <h5 className="profile_name">{this.consultant.consultantName}</h5>
          Hourly Rate: £{this.consultant.hourlyRate}
          <Link to={`/${this.consultant.slug}/book/`}>
            <Button
              className="btn btn--sm"
              style={{ marginTop: "30px", width: "100%" }}
            >
              Book {this.consultant.firstName}
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default ProfileInfo;
