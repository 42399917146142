import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import HourlyButton from "../components/buttons/hourly-button";

const ConsultantBio = styled.h4`
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 500;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 1em;
  }
`;
const ConsultantName = styled.h5`
  font-size: 1em;
  margin-bottom: 24px;
  @media (max-width: 480px) and (min-width: 320px) {
    font-size: 0.9em;
  }
`;

const ConsultantCard = props => {
  const consultant = props.consultant;
  return (
    <div className="showcase__item">
      <Link to={`/${consultant.slug}`}>
        <figure className="card">
          <Img
            className="card_profile-image"
            fluid={consultant.consultantImage.fluid}
          />

          <figcaption className="card__caption">
            <ConsultantBio
              dangerouslySetInnerHTML={{
                __html: consultant.bioSnippet
              }}
            />
            <ConsultantName>{consultant.consultantName}</ConsultantName>
            <HourlyButton text="View Profile" size="sm" />
          </figcaption>
        </figure>
      </Link>
    </div>
  );
};
export default ConsultantCard;
