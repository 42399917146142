import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
const AreasOfStrength = props => {
  const consultant = props.consultant;
  return consultant.areasOfStrength.map((areaOfStrength, index) => (
    <Jumbotron key={areaOfStrength.id}>
      <div>
        <h4 style={{ marginBottom: "16px" }}>{areaOfStrength.title}</h4>
        <div
          className="lead"
          dangerouslySetInnerHTML={{
            __html: areaOfStrength.strengthText
          }}
        />
      </div>
    </Jumbotron>
  ));
};

export default AreasOfStrength;
