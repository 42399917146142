import React, { Component } from "react";
import { Link } from "gatsby";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import logo from "../assets/images/logo-black.png";
import netlifyIdentity from "netlify-identity-widget";
//import("../assets/css/hourly.css");
//import("../assets/css/masonary.css");
/*
	<link
  rel="stylesheet"
  href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
  crossorigin="anonymous"
/>
*/
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
    //this.props = props;
  }

  componentDidMount() {
    netlifyIdentity.init();
    const user = netlifyIdentity.currentUser();
    console.log("Layout", user);
    if (user) {
      console.log("true");
      this.setState({
        loggedIn: true
      });
    } else {
      //alert('NOT Logged In');
      console.log("false");
      this.setState({
        loggedIn: false
      });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Hourly Consulting</title>
          <meta
            name="description"
            content="Hourly gives you access to top flight consultants with a proven track record in startup consulting, tech, investment and recruiting and we do it by the hour. "
          />
          <link rel="canonical" href="https://hourly.consulting" />
        </Helmet>

        <Navbar expand="lg" bg="light" style={{ padding: "20px 0px" }}>
          <Container>
            <Link to="/">
              <img alt="Hourly Logo" className="logo" src={logo} />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav.Link
                to="/meet-our-consultants/"
                className="btn btn--sm  type--uppercase nav_button_2 nav-link"
              >
                <span className="btn__text">Consultants</span>
              </Nav.Link>
              <Nav.Link
                href="https://indieridge.com"
                className="btn btn--sm btn--primary type--uppercase nav_button_2"
              >
                <span className="btn__text">AGENCY</span>
              </Nav.Link>
              {/*
              {this.state.loggedIn ? (
                <Nav.Link
                  href="https://indieridge.com"
                  className="btn btn--sm btn--primary type--uppercase nav_button_2"
                >
                  <span className="btn__text">Log out</span>
                </Nav.Link>
              ) : (
                <Nav.Link
                  href="https://indieridge.com"
                  className="btn btn--sm  nav_button_2"
                >
                  <span className="btn__text">Log in</span>
                </Nav.Link>
              )}
              */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {this.props.children}
        <footer className="section">
          <Col>
            <h6>&copy; 2019 - Indie Ridge. All rights reserved.</h6>
            <br />
            <Link to="/">
              <img alt="Hourly Logo" className="logo" src={logo} />
            </Link>
          </Col>
        </footer>
      </div>
    );
  }
}
export default Layout;
