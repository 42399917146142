import React from "react";

import { Link, graphql } from "gatsby";
import IRLayout from "../components/layout";
import Masonry from "react-masonry-component";
import Img from "gatsby-image";

import imageTopTalent from "../assets/images/top-talent.svg";
import imageNoRisk from "../assets/images/no-risk.svg";
import imageAffordable from "../assets/images/affordable.svg";

import ConsultantCard from "../components/consultant-card";

import Card from "react-bootstrap/Card";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const IndexPage = ({ data }) => (
  <IRLayout>
    <div className="section white">
      <Container>
        <Row className="my-row">
          <Col xs={12} md={6} className="home_hero_text">
            <h1 style={{ marginBottom: "24px" }}>
              Accelerate your business with Hourly
            </h1>

            <p className="lead">
              Hourly gives you access to top flight consultants with a proven
              track record in startup consulting, tech, investment and
              recruiting and we do it by the hour. No costly spin up, no long
              term commitments and no 6 figure budgets.
            </p>
            <p className="lead">Expert help exactly when you need it.</p>
            <p>
              <Link to={`/meet-our-consultants/`}>View our consultants</Link>
            </p>
          </Col>
          <Col xs={12} md={6}>
            <Img
              fluid={data.rocket.childImageSharp.fluid}
              alt="Accelerate your business with Hourly"
            />
          </Col>
        </Row>
      </Container>
    </div>
    <div className="section">
      <Container>
        <Row className="my-row">
          <Col>
            <h1 className="centered text-padding">The Hourly Difference</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="my-row">
          <Col xs={12} md={4}>
            <Card style={{ minHeight: "550px" }}>
              <Card.Body>
                <h2>Top Talent</h2>
                <h5>We only hire the best</h5>
                <img
                  style={{ margin: "30px 0px", width: "100%" }}
                  alt="Top Talent"
                  src={imageTopTalent}
                />
                Our consultants are at the top of their games and have been
                carefully curated to provide you with high level help with
                strategic decisions.
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card style={{ minHeight: "550px" }}>
              <Card.Body>
                <h2>No Risk</h2>
                <h5>Try before you commit</h5>
                <img
                  style={{ margin: "30px 0px", width: "100%" }}
                  alt="No Risk"
                  src={imageNoRisk}
                />
                All of our consultants offer an initial 15 minute spin up period
                – bail before this and you won’t pay a thing.
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4}>
            <Card style={{ minHeight: "550px" }}>
              <Card.Body>
                <h2>Affordable</h2>
                <h5>Just the right amount of help</h5>
                <img
                  style={{ margin: "30px 0px", width: "100%" }}
                  alt="Affordable Consulting"
                  src={imageAffordable}
                />
                Perfect for executives and founders who are wrestling with
                strategic decisions. You’ll be amazed at what can get unlocked
                in an hour talking to the right person.
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="section white">
      <Container>
        <Row className="my-row">
          <Col>
            <h1 className="centered text-padding">Meet our consultants</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Col xs={12}>
          <Masonry className="showcase">
            {data.allDatoCmsConsultant.edges.map(({ node: consultant }) => (
              <ConsultantCard key={consultant.id} consultant={consultant} />
            ))}
          </Masonry>
        </Col>
      </Container>
    </div>
  </IRLayout>
);

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allDatoCmsConsultant(sort: { fields: [position], order: [ASC] }) {
      edges {
        node {
          id
          consultantName
          bio
          slug
          bioSnippet
          hourlyRate
          areasOfStrength {
            ... on DatoCmsStrength {
              title
              strengthText
            }
          }
          consultantImage {
            fluid(maxWidth: 450, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }

    topTalent: file(relativePath: { eq: "rocket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    rocket: file(relativePath: { eq: "rocket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    noRisk: file(relativePath: { eq: "no-risk.svg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    affordable: file(relativePath: { eq: "affordable.svg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
